<template>
  <div>
    <div class="card">
      <div class="head_warp">
        <div class="card_head">
          <span>题目编号:</span>{{info.question_id}}
        </div>

      </div>
      <div class="box-card">
        <div class="neirong">
          <span class="left_head">题目内容：</span>
        </div>
        <div style="padding:10px">
          <div v-html="info.question_head_content"
               v-if="info.question_head_content !== ''"></div>
          <div v-html="info.question_content">

          </div>
        </div>
      </div>
      <div class="box-card">
        <div class="neirong">
          <span class="left_head">题目解释：</span>
        </div>
        <div style="padding:10px"
             v-html="info.question_explain">
        </div>
      </div>
      <div v-if="info.check_id == 0">
        <span class="left_head">题目答案：{{info.question_answer}},用户答案:{{info.user_answer}}</span>
      </div>
      <div class="box-card"
           v-else>
        <div class="neirong">
          <span class="left_head">题目答案</span>
        </div>
        <div v-html="info.question_answer">

        </div>
      </div>
      <div style="padding:10px"
           v-if="info.check_id == 0">
        <span>题目分数:{{info.question_score}}, 用户得分:{{info.is_ignored==1?'':info.user_score}}</span>
      </div>
      <Form ref="FormRef" />
      <div class="center">
        <el-button type="primary"
                   style="margin-left:15px"
                   @click="back">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Form from './form.vue'
export default {
  data () {
    return {
      info: {}
    }
  },
  components: {
    Form
  },
  mounted () {
    this.getInfo()
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v1/papers/question_detail',
        method: 'get',
        params: {
          user_paper_detail_id: this.$route.query.user_paper_detail_id
        }
      }).then(res => {
        this.info = res.data.list
        var msg = 'src="/'
        this.info.question_head_content = this.info.question_head_content.replace(new RegExp(msg, 'g'), `src="${this.$basicUrl}/`)
        this.info.question_content = this.info.question_content.replace(new RegExp(msg, 'g'), `src="${this.$basicUrl}/`)
        this.info.question_explain = this.info.question_explain.replace(new RegExp(msg, 'g'), `src="${this.$basicUrl}/`)
        this.info.question_answer = this.info.question_answer.replace(new RegExp(msg, 'g'), `src="${this.$basicUrl}/`)
      })
    },
    back () {
      this.$router.go('-1')
    },
    collect () {
      // console.log('this.info', this.info);
      this.$refs.FormRef.question_id = this.info.question_id
      this.$refs.FormRef.dialogVisible = true
    }

  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 20px;
  background: white;
  .head_warp {
    display: flex;
    justify-content: space-between;
    background-color: #eff8ff;
  }
  .card_head {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    line-height: 22px;
    height: 22px;
    font-size: 16px;
    color: #000000;
    span {
      display: inline-block;
      margin-right: 5px;
    }
  }
}
.collect {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  img {
    width: 24px;
  }
}
.center {
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 10px;
}

.box-card {
  border: 1px solid e6e6e6;
  margin-bottom: 5px;
  .neirong {
    margin-top: 13px;
    word-break: break-all;
    padding: 5px;
  }
}

.left_head {
  display: inline-block;
  border-left: 4px solid #3f9eff;
  padding-left: 15px;
  font-weight: bold;
  font-size: 16px;
}
</style>